import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-scroll';

import Logo from '../../../static/svg/logo.svg';

import './Header.scss';

const componentClass = 'header';
const logoClass = `${componentClass}__logo`;
const menuClass = `${componentClass}__menu`;

const Header = () => {
  const menu = {
    sluzby: 'Služby',
    technika: 'Naše technika',
    reference: 'Reference',
    kontakt: 'Kontakt'
  };

  return (
    <div className={componentClass}>
      <Logo className={logoClass} />

      <ul className={menuClass}>
        {Object.keys(menu).map(key => {
          return (
            <li key={key}>
              <Link to={key} smooth={true} duration={500}>
                {menu[key]}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Header;
